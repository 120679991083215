import React from 'react';
import styled from 'styled-components';
import { WizardIcon } from './WizardIcon';
import colors from 'src/theme/colors';
import { Variable } from 'src/api/types';
import { currencyFormatter } from '../util';
import _ from 'lodash';
import { NoData } from 'src/common';

const Div = styled.div`
  display: grid;
  grid-template-columns: 12px auto auto;
  grid-gap: 6px;
  border-radius: 4px;
  font-weight: 500;
  padding: 4px 8px;

  .se-item {
    align-self: center;

    &__center {
      align-self: center;
      justify-self: center;
    }
    &__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 350px;
      margin-right: 10px;
      color: #555862;
    }
    &__end {
      justify-self: end;
      color: ${colors.cflowerBlue};
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:active,
  &:hover,
  &:focus,
  &:visited,
  &.myz2dw1 {
    background: ${colors.gradient};
    color: white;
    .se-item {
      &__name {
        color: white;
      }
      &__end {
        color: white;
      }
    }
    path {
      fill: white;
    }
  }
`;

interface SuggestionEntryProps {
  mention: Variable;
}

export const SuggestionEntryComponent: React.FC<SuggestionEntryProps> = ({ mention, ...rest }) => {
  return (
    <Div {...rest}>
      <div className="se-item se-item__center">
        {(mention.workspace_variable || mention.isGlobal) && <WizardIcon />}
      </div>
      <div className="se-item se-item__name">
        <span>{mention.alias || mention.name}</span>
      </div>
      {!_.isNil(mention.value) ? (
        <div className="se-item se-item__end">{currencyFormatter(mention.value, !mention.currency)}</div>
      ) : (
        <div className="se-item se-item__end">
          <NoData iconOnly techIncomplete />
        </div>
      )}
    </Div>
  );
};
