import React, { memo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getControls } from 'src/api/controls';
import { getScenarioControls } from 'src/api/plan';
import { QKeys } from 'src/api/types';
import { useColumns } from './useColumns';
import GenericTable, { TableSheetRmp } from 'src/components/GenericTable';
import { Spacer, TableSkeleton } from 'src/common';
import { isEqual } from 'lodash';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

interface FromExistingProps {
  scenarioId: string;
  setChecked: (a: string[]) => void;
  isLoading: boolean;
  onSubmit: () => void;
}

export const FromExisting: React.FC<FromExistingProps> = memo(({ scenarioId, setChecked, isLoading, onSubmit }) => {
  const { data: controls = [], isLoading: isControlsLoading } = useQuery(QKeys.Controls, getControls);
  const { data: scenarioControls = [], isLoading: isScenarioControlsLoading } = useQuery(
    [QKeys.ScenarioControls, scenarioId],
    () => getScenarioControls(scenarioId),
  );

  const { columns, GTColumnSelectAnchorExported, checked } = useColumns({
    data: controls,
    linkedControls: scenarioControls,
    scenarioId,
  });

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <>
      {!isControlsLoading && !isScenarioControlsLoading ? (
        <>
          <GenericTable
            tableId={TableIds.scenarioLinkExistingControl}
            data={controls}
            columns={columns}
            GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
            searchable={['strategy', 'name', 'owner', 'frameworkLibrary_shortname', 'controlId', 'status']}
            expandContent={(data) => <TableSheetRmp data={data} />}
            itemHeight={50}
          />
          <Spacer $px={30} />
          <Button
            disabled={isLoading || !checked.length}
            onClick={() => {
              onSubmit();
              mpEvent(MPEvents.ButtonClick, {
                button: 'Add',
                modal: 'Link controls to scenario modal',
                tags: ['SCENARIO', 'RMP'],
              });
            }}
            primary
            css="width: 100%;"
            data-cy="submit-btn"
          >
            + ADD
          </Button>
        </>
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}, isEqual);
