import React from 'react';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { ReactComponent as SheetIcon } from 'src/assets/sheets.svg';
import colors from 'src/theme/colors';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { useAssessment } from 'src/state/assessment';
import { useAuth } from 'src/state/auth';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { WizardIntent } from 'src/redux/reducers/app/types';

const EmptySubTitle = styled.div`
  letter-spacing: 0.3px;
  font-weight: normal;
  color: ${colors.davysGrey};
  text-align: center;
  max-width: 600px;
  margin-top: 25px;
`;

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  flex: 1;
  padding: 0 40px;

  .btns {
    display: flex;
    grid-gap: 20px;
  }
`;

export const Empty = () => {
  const { openModal } = useAssessment();
  const { userFeatures } = useAuth();
  const { setWizardWorkspace } = useActionsApp();

  return (
    <EmptyContainer>
      <SheetIcon />
      <Poppins css="margin-top: 67px;" px={32} weight={600}>
        Welcome!
      </Poppins>
      <EmptySubTitle>
        <Poppins px={18} color="davysGrey">
          It looks like you haven’t created any assessments yet. Click the button below to add a new assessment and set
          its scope.
        </Poppins>
      </EmptySubTitle>
      <Spacer $px={55} />
      <div className="btns">
        <Button
          onClick={() => {
            openModal();
            mpEvent(MPEvents.ButtonClick, {
              button: 'Create Assessment',
              tags: ['ASSESSMENT'],
            });
          }}
          primary
          data-cy="assessment-add-button-first"
        >
          + CREATE ASSESSMENT
        </Button>
        {/* {userFeatures.app.wizard && (
          <Button
            onClick={() => {
              setWizardWorkspace({ intent: { type: WizardIntent.assessment } });
              mpEvent(MPEvents.ButtonClick, {
                button: 'Assessment Wizard',
                tags: ['ASSESSMENT', 'WIZARD'],
              });
            }}
            primary
            data-cy="assessment-add-button-first-wizard"
          >
            + ASSESSMENT WIZARD
          </Button>
        )} */}
      </div>
    </EmptyContainer>
  );
};
