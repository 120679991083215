import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment, { Moment } from 'moment';
import MaterialInput from '../MaterialInput';
import { DATE_FORMAT_PICKER } from 'src/config';
import { IconButton, InputAdornment } from '@mui/material';
import { ReactComponent as CalendarIcon } from 'src/assets/calendar.svg';
import styled from 'styled-components';
import colors from 'src/theme/colors';
import { PickersDay } from '@mui/x-date-pickers';
import { ActionBar, Toolbar } from './comps';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const StyledDay = styled(PickersDay)`
  font-weight: 500 !important;
  &.Mui-selected {
    background: ${colors.gradient} !important;
  }
`;

interface InputProps extends React.ComponentProps<typeof MaterialInput> {
  ['data-cy']?: string;
}

interface MaterialDatePickerProps extends Omit<React.ComponentProps<typeof DatePicker>, 'value' | 'onChange'> {
  value: string | null | undefined;
  onChange: (date: string | null) => void;
  inputProps?: InputProps;
}

const MaterialDatePicker: React.FC<MaterialDatePickerProps> = ({ value, inputProps, onChange, ...rest }) => {
  const [open, setOpen] = useState(false);

  const handleOnDateChange = (date: Moment | null) => {
    onChange(date ? date.toISOString() : null);
    setOpen(false);
  };

  return (
    <MobileDatePicker
      value={value ? moment(value) : null}
      onChange={handleOnDateChange}
      format={DATE_FORMAT_PICKER}
      onClose={() => setOpen(false)}
      open={open}
      slots={{
        // textField: MaterialInput,
        textField: (p) => (
          <MaterialInput
            {...p}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setOpen(!open)}
                      sx={{
                        marginRight: 1,
                      }}
                    >
                      <CalendarIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        ),
        // openPickerButton: (p) => <IconButton {...p} sx={{ marginRight: 1 }}></IconButton>,
        // openPickerIcon: CalendarIcon,
        toolbar: Toolbar,
        actionBar: (p) => <ActionBar {...p} onPeriodSelect={handleOnDateChange} />,
        day: StyledDay,
      }}
      slotProps={{
        // @ts-ignore
        textField: {
          ...inputProps,
          onClick: (e) => {
            inputProps?.onClick && inputProps.onClick(e);
            setOpen(!open);
          },
        },
        toolbar: {
          toolbarFormat: DATE_FORMAT_PICKER,
        },
        openPickerButton: {
          onClick: () => {
            setOpen(!open);
          },
        },
      }}
      {...rest}
    />
  );
};
export default MaterialDatePicker;
