import { Variable } from 'src/api/types';
import { StandardVariableValues } from './types';
import _ from 'lodash';

export const genStandardVariableValues = (variables: Variable[]): StandardVariableValues => {
  return {
    annualRevenue: variables.find((v) => v.id === 500001)?.value || undefined,
    numberOfStaff: variables.find((v) => v.id === 500002)?.value || undefined,
    annualWageBill: variables.find((v) => v.id === 500003)?.value || undefined,
    avgWorkingDays: variables.find((v) => v.id === 500004)?.value || undefined,
    annualCyberSpend: variables.find((v) => v.id === 500005)?.value || undefined,
    avgStaffHardwareCost: variables.find((v) => v.id === 500006)?.value || undefined,
    numberOfCustomers: variables.find((v) => v.id === 500007)?.value || undefined,
    avgContractValue: variables.find((v) => v.id === 500008)?.value || undefined,
    numberOfDataRecords: variables.find((v) => v.id === 500009)?.value || undefined,
    releaseCycleCost: variables.find((v) => v.id === 5000010)?.value || undefined,
    releaseCycleLength: variables.find((v) => v.id === 5000011)?.value || undefined,
    researchAndDevelopmentBudget: variables.find((v) => v.id === 5000012)?.value || undefined,
    avgCostToProcureSupplier: variables.find((v) => v.id === 5000013)?.value || undefined,
    avgInvoiceValue: variables.find((v) => v.id === 5000014)?.value || undefined,
    largestRegularInvoiceValue: variables.find((v) => v.id === 5000015)?.value || undefined,
    cyberInsuranceLimit: variables.find((v) => v.id === 5000016)?.value || undefined,
    cyberInsuranceExcess: variables.find((v) => v.id === 5000017)?.value || undefined,
    cyberInsurancePremium: variables.find((v) => v.id === 5000018)?.value || undefined,
  };
};

export type CheckIfVariablesPopulated = (
  variables: StandardVariableValues,
  params: { developsSoftware: boolean; cyberInsurance: boolean },
) => boolean;

export const checkIfVariablesPopulated: CheckIfVariablesPopulated = (
  variables: StandardVariableValues,
  { developsSoftware, cyberInsurance },
): boolean => {
  let testVars = _.cloneDeep(variables);
  if (!developsSoftware) {
    testVars = _.omit(testVars, ['releaseCycleCost', 'releaseCycleLength', 'researchAndDevelopmentBudget']);
  }
  if (!cyberInsurance) {
    testVars = _.omit(testVars, ['cyberInsuranceLimit', 'cyberInsuranceExcess', 'cyberInsurancePremium']);
  }

  console.log('testVars', testVars);
  return Object.values(testVars).every((v) => v !== undefined);
};
