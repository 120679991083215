import { format, isValid } from 'date-fns';
import _ from 'lodash';
import { UserOptionType } from 'src/api/types';
import { DATE_FORMAT } from 'src/config';

export const sanitizeForCSV = (input: any) => {
  if (!input) return '';

  if (typeof input === 'number') {
    return input;
  }

  if (/^(=|\+|-|@|DDE|\t|\n|\r)/.test(input)) {
    return "'" + input;
  }

  return input;
};

export const currencyFormatter = (num: number, disablePrefix?: boolean) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  let str = formatter.format(num || 0);

  if (disablePrefix) {
    return str.replace(/£/g, '');
  }

  return str;
};

export const isUndefined = (value: any) => {
  return typeof value === 'undefined' || value === null;
};

export const formatDate = (
  date: string | null,
  params?: {
    dateFormat?: string;
  },
) => {
  const dateFormat = params?.dateFormat || DATE_FORMAT;
  if (!date) return '';
  return isValid(new Date(date)) ? format(new Date(date), dateFormat) : '';
};

export const getOrgRoleLabel = (permissions?: { [key: string]: '0' | '1' } | null) => {
  if (!permissions) {
    return '';
  }
  if (permissions['platform.admin'] === '1') {
    return 'Platform Administrator';
  }
  if (permissions['app.organisation.edit_users'] === '1') {
    return 'Organisation Administrator';
  }
  if (permissions['app.organisation.manage_users'] === '1') {
    return 'Workspace Administrator';
  }

  return 'Collaborator';
};

export const formatNumber = (number: number) => {
  if (Number.isInteger(number)) {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(number);
  } else {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  }
};

export const waitFor = async (ms: number) => {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve(null);
      clearTimeout(timer);
    }, ms);
  });
};

export const getPercentage = (a: number, b: number) => {
  let percent = (a / b) * 100;
  return Math.min(Math.round(percent), 100);
  // return Math.round(percent);
};

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

export const formatUrl = (input?: string | null): string => {
  if (!input) return '';

  if (input.startsWith('http://') || input.startsWith('https://')) {
    return isValidUrl(input) ? input.replace(/\s+/g, '') : '';
  }

  if (input.startsWith('www.')) {
    const url = 'https://' + input;
    return isValidUrl(url) ? url.replace(/\s+/g, '') : '';
  }

  if (input.includes('.')) {
    const url = 'https://' + input;
    return isValidUrl(url) ? url.replace(/\s+/g, '') : '';
  }

  return '';
};

type SelectRiskOwner = (a: {
  usersOptios: UserOptionType[];
  selectedUser?: UserOptionType | null;
  initialUser?: UserOptionType | null;
}) => {
  userAdd?: UserOptionType | null;
  userUpdate?: UserOptionType | null;
};

export const selectUserOption: SelectRiskOwner = ({ usersOptios, selectedUser, initialUser }) => {
  const debug = false;

  if (!selectedUser?.text) {
    debug && console.log('<USER:REMOVE>');
    return { userAdd: initialUser ? null : undefined, userUpdate: initialUser ? null : undefined };
  }
  const isSame = _.isEqual(_.omit(selectedUser, 'id'), _.omit(initialUser, 'id'));

  if (selectedUser.email) {
    const user = usersOptios.find((el) => el.email === selectedUser.email);
    if (user) {
      const userAdd = user;
      const userUpdate = isSame ? undefined : user;
      debug && console.log('<USER:EMAIL>', { userAdd, userUpdate });
      return {
        userAdd,
        userUpdate,
      };
    }
  }

  const user = usersOptios.find((el) => el.text === selectedUser.text);

  if (user) {
    const userAdd = user;
    const userUpdate = isSame ? undefined : user;
    debug && console.log('<USER:TEXT>', { userAdd, userUpdate });
    return {
      userAdd,
      userUpdate,
    };
  }

  debug && console.log('<USER:NEW>', { userAdd: selectedUser, userUpdate: selectedUser });
  return { userAdd: selectedUser, userUpdate: selectedUser };
};

// DEPRECATED
type CheckIfUserNameTaken = (a: {
  usersOptios: UserOptionType[];
  text?: string | null;
  excludedNames?: (string | undefined)[];
}) => boolean | string;

export const USER_TAKEN_ERR = 'User name already taken';
export const checkIfUserNameTaken: CheckIfUserNameTaken = ({ usersOptios, text, excludedNames }) => {
  if (!text) return false;

  const exNames = (excludedNames?.filter((el) => !!el) || []) as string[];

  return usersOptios
    .filter((el) => el.text)
    .filter((el) => !exNames.map((el) => el.toLowerCase()).includes(el.text.toLowerCase()))
    .some((el) => el.text.toLowerCase() === text.toLowerCase())
    ? USER_TAKEN_ERR
    : false;
};

export const removeCommas = (str: string): string => {
  return String(str).replace(/,/g, '');
};

export const getPageRange = (current: number, total: number, max: number) => {
  let start = Math.max(current - Math.floor(max / 2), 0);
  let end = Math.min(start + max - 1, total - 1);

  if (end - start + 1 < max) {
    start = Math.max(end - max + 1, 0);
  }

  return { start, end };
};
