import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getScenario } from 'src/api/scenario';
import { useParams } from 'react-router';
import { ProjectionVariant, QKeys, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import Button from 'src/components/form/Button';
import numeral from 'numeral';
import Meta from 'src/components/Meta';
import {
  DataTitle,
  DataValue,
  GradientText,
  NoData,
  PageCard,
  PageChartSkeleton,
  PageContainer,
  Poppins,
  Spacer,
} from 'src/common';
import ExpandCollapse from 'src/common/ExpandCollapse';
import Audit from 'src/components/Audit';
import { useGaEventPageView } from '../../utils/gaEvent';
import { LogSwitch } from 'src/components/charts/comps';
import { Incidents, Head, Controlls, VersionedChart, LegendComposed } from './comps';
import useVersion from 'src/hooks/useVersion';
import { ManageRiskModal } from 'src/components/modals';
import ChartBlank from 'src/components/charts/ChartBlank';
import Chart from 'src/components/charts/Chart';
import colors from 'src/theme/colors';
import { formatDate } from 'src/utils/misc';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { TableIds } from 'src/api/types/misc';
import { useAuth } from 'src/state/auth';

const ChartBox = styled(PageCard)`
  .exceedance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__end {
      display: flex;
      align-items: center;
      grid-gap: 20px;
    }
  }
  .note-text {
    word-break: break-word;
  }
  .content {
    display: flex;
    justify-content: space-between;
  }

  .notes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .exceedance {
      &__end {
        grid-gap: 10px;
      }
    }
    .risk-statement {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
    }
    .notes {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .risk-statement {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const HDivider = styled.div`
  position: absolute;
  left: 0;
  height: 1px;
  background: ${colors.stroke};
  width: 100%;
`;

const ScenarioPage = () => {
  const { scenarioId, assessmentId } = useParams<{ scenarioId: string; assessmentId: string }>();
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const [isManageRiskModalOpen, setIsManageRiskModalOpen] = useState(false);
  const { version } = useVersion();
  const { setLocationPaths } = useActionsApp();
  const isMd = useBreakpoints('md');
  const { userFeatures } = useAuth();

  const {
    data: scenario,
    refetch,
    isLoading,
  } = useQuery([QKeys.Scenario, { scenarioId, version, variant: ProjectionVariant.live }], getScenario);

  const { data: scenarioBase, isLoading: isLoadingScenarioBase } = useQuery(
    [QKeys.Scenario, { scenarioId, version, variant: ProjectionVariant.base }],
    getScenario,
  );

  useEffect(() => {
    if (scenario) {
      setLocationPaths({
        assessment: { id: scenario.assessment_id, name: scenario.assessment_name },
        scenario: { id: scenario.id, name: scenario.name },
      });
    }
    return () => {
      setLocationPaths({ assessment: undefined, scenario: undefined });
    };
  }, [scenario]);

  useGaEventPageView('Cydea | Scenario');

  const useBaseScenario =
    scenarioBase &&
    scenarioBase.upper &&
    scenarioBase.lower &&
    scenarioBase.frequency_times &&
    scenarioBase.frequency_years;
  const effectiveScenario = useBaseScenario ? scenario : scenarioBase;

  const lowerThanMinimum = JSON.parse(effectiveScenario?.lower_than_minimum || '[]');
  const canRenderChart = !(
    lowerThanMinimum.length > 0 ||
    effectiveScenario?.expected_loss === null ||
    effectiveScenario?.is_incomplete === 3
  );

  if (isLoading || isLoadingScenarioBase)
    return (
      <PageContainer>
        <PageChartSkeleton />
      </PageContainer>
    );

  return (
    <>
      {effectiveScenario && scenarioBase && (
        <>
          <Meta title={`Cydea | ${effectiveScenario?.name}`} feedbackScript />
          {isManageRiskModalOpen && (
            <ManageRiskModal
              scenarioId={scenarioId as string}
              scenarioDetails={effectiveScenario}
              onClose={() => {
                setIsManageRiskModalOpen(false);
                refetch();
              }}
              subTitle={{
                title: 'SCENARIO',
                value: effectiveScenario.name,
              }}
            />
          )}
          <PageContainer data-cy="scenario-page">
            <ChartBox>
              <Head scenario={effectiveScenario} isLogarithmic={isLogarithmic} />
              <Spacer $px={20} />
              <HDivider />
              <Spacer $px={20} />
              <div className="exceedance">
                <GradientText>LOSS EXCEEDANCE CURVE</GradientText>
                <div className="exceedance__end">
                  {version && !effectiveScenario.legacy_version && canRenderChart && !isMd && <LegendComposed />}
                  {effectiveScenario.expected_loss != null && (
                    <LogSwitch isLogarithmic={isLogarithmic} setIsLogarithmic={setIsLogarithmic} />
                  )}
                </div>
              </div>
              {version && !effectiveScenario.legacy_version && canRenderChart && isMd && (
                <div css="display: flex;">
                  <LegendComposed />
                </div>
              )}
              <Spacer $px={20} />
              {!canRenderChart ? (
                <ChartBlank lowerThanMinimum={lowerThanMinimum || []} techIncomplete />
              ) : (
                <>
                  {version ? (
                    <VersionedChart isLogarithmic={isLogarithmic} isLegacyVersion={effectiveScenario.legacy_version} />
                  ) : (
                    <Chart
                      isLogarithmic={isLogarithmic}
                      input={[
                        {
                          data: effectiveScenario?.chart || [],
                          upper: effectiveScenario?.upper || 1,
                          lower: effectiveScenario?.lower || 1,
                          name: 'Loss Exceedance',
                          color: colors.gradientPart,
                          fillColor: colors.aliceBlue,
                          legendId: 'legend1',
                        },
                      ]}
                    />
                  )}
                </>
              )}
              <Spacer $px={30} />
              <HDivider />
              <Spacer $px={30} />

              <GradientText>RISK STATEMENT</GradientText>
              <Spacer $px={8} />
              <div className="content risk-statement">
                <div>
                  <DataTitle>SOURCES</DataTitle>
                  {riskSourcesNames[effectiveScenario.source] ? (
                    <DataValue data-cy="scenario-source">{riskSourcesNames[effectiveScenario.source]}</DataValue>
                  ) : (
                    <NoData />
                  )}
                </div>
                <div>
                  <DataTitle>EVENTS</DataTitle>
                  {riskEventsNames[effectiveScenario.event] ? (
                    <DataValue data-cy="scenario-event">{riskEventsNames[effectiveScenario.event]}</DataValue>
                  ) : (
                    <NoData />
                  )}
                </div>
                <div>
                  <DataTitle>CONSEQUENCES</DataTitle>
                  {riskConsequencesNames[effectiveScenario.consequence] ? (
                    <DataValue data-cy="scenario-consequence">
                      {riskConsequencesNames[effectiveScenario.consequence]}
                    </DataValue>
                  ) : (
                    <NoData />
                  )}
                </div>
                <div>
                  <DataTitle>IDENTIFIED DATE</DataTitle>
                  {effectiveScenario.risk_identified_date ? (
                    <DataValue data-cy="scenario-risk_identified_date">
                      {formatDate(effectiveScenario.risk_identified_date)}
                    </DataValue>
                  ) : (
                    <NoData />
                  )}
                </div>
                <div>
                  <DataTitle>REVIEW DATE</DataTitle>
                  {effectiveScenario.review_date ? (
                    <DataValue data-cy="scenario-review_date">{formatDate(effectiveScenario.review_date)}</DataValue>
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
              <Spacer $px={30} />
              <DataTitle>RISK NARRATIVE</DataTitle>
              <div />
              {effectiveScenario.risk_narrative ? (
                <ExpandCollapse text={effectiveScenario.risk_narrative} />
              ) : (
                <NoData />
              )}
              <Spacer $px={30} />
              <div className="notes">
                <div>
                  <DataTitle>FREQUENCY</DataTitle>
                  {lowerThanMinimum.includes('frequency') ? (
                    <DataValue data-cy="scenario-frequency">Frequency lower than 1 time every 100 years</DataValue>
                  ) : (
                    <Poppins px={14} weight={500} css="display: flex; align-items: center;">
                      {(effectiveScenario.frequency_times || '-') === '-' ||
                      (effectiveScenario.frequency_years || '-') === '-' ? (
                        <>
                          <div style={{ marginRight: '5px' }}>
                            <NoData iconOnly techIncomplete />
                          </div>
                          {`${effectiveScenario.frequency_times || '-'} time(s) per ${
                            effectiveScenario.frequency_years || '-'
                          } year(s)`}
                        </>
                      ) : (
                        <DataValue data-cy="scenario-frequency">{`${
                          effectiveScenario.frequency_times || '-'
                        } time(s) per ${effectiveScenario.frequency_years || '-'} year(s)`}</DataValue>
                      )}
                    </Poppins>
                  )}
                  <Spacer $px={30} />
                  <DataTitle>NOTE</DataTitle>
                  {effectiveScenario.frequency_note ? (
                    <DataValue className="note-text" data-cy="scenario-frequency-note">
                      {effectiveScenario.frequency_note}
                    </DataValue>
                  ) : (
                    <NoData />
                  )}
                </div>
                <div>
                  <DataTitle>CONSEQUENCE</DataTitle>
                  <div css="display: flex;justify-content: space-between; ">
                    <div css="display: flex;align-items: center;">
                      <DataTitle $noMargin>LOWER</DataTitle>
                      {lowerThanMinimum.includes('lower') ? (
                        <DataValue css="margin-left: 12px;" data-cy="scenario-lower">
                          {'<1'}
                        </DataValue>
                      ) : (
                        <>
                          {effectiveScenario.lower === null ? (
                            <div style={{ marginLeft: '5px' }}>
                              <NoData techIncomplete />
                            </div>
                          ) : (
                            <DataValue css="margin-left: 12px;" data-cy="scenario-lower">
                              {numeral(effectiveScenario.lower).format('$0a')}
                            </DataValue>
                          )}
                        </>
                      )}
                    </div>
                    <div css="display: flex;align-items: center;">
                      <DataTitle $noMargin>UPPER</DataTitle>
                      {lowerThanMinimum.includes('upper') ? (
                        <DataValue css="margin-left: 12px;" data-cy="scenario-upper">
                          {'<1'}
                        </DataValue>
                      ) : (
                        <>
                          {effectiveScenario.upper === null ? (
                            <div style={{ marginLeft: '5px' }}>
                              <NoData techIncomplete />
                            </div>
                          ) : (
                            <DataValue css="margin-left: 12px;" data-cy="scenario-upper">
                              {numeral(effectiveScenario.upper).format('$0a')}
                            </DataValue>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <Spacer $px={30} />
                  <div>
                    <DataTitle>NOTE</DataTitle>
                    {effectiveScenario.note ? (
                      <DataValue className="note-text" data-cy="scenario-sonsequence-note">
                        {effectiveScenario.note}
                      </DataValue>
                    ) : (
                      <NoData />
                    )}
                  </div>
                </div>
              </div>
              <Spacer $px={30} />
              <HDivider />
              <Spacer $px={30} />

              <div css="display: flex;justify-content: space-between">
                <GradientText>RISK MANAGEMENT PROPOSAL</GradientText>
                {!version && (
                  <Button
                    primary
                    onClick={() => {
                      setIsManageRiskModalOpen(true);
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Select strategy',
                        tags: ['SCENARIO'],
                      });
                    }}
                    data-cy="manage-risk-btn"
                    $constWidth={200}
                  >
                    Select Strategy
                  </Button>
                )}
              </div>
              <div className="content">
                <div>
                  <DataTitle>MANAGEMENT STRATEGY</DataTitle>
                  {effectiveScenario.management_strategy ? (
                    <DataValue data-cy="rm-strategy-label">{effectiveScenario.management_strategy}</DataValue>
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
              <Spacer $px={10} />
              <div className="content">
                <DataTitle>NOTE</DataTitle>
              </div>
              <div />
              {effectiveScenario.management_note ? (
                <ExpandCollapse text={effectiveScenario.management_note} />
              ) : (
                <NoData />
              )}

              <Spacer $px={30} />
              <HDivider />
              <Spacer $px={30} />
              <Controlls scenario={scenarioBase} />
              <Spacer $px={30} />
              <HDivider />
              <Spacer $px={30} />
              <Incidents scenario={scenarioBase} />
              <Spacer $px={30} />
            </ChartBox>
            {userFeatures.app.audit.report && (
              <Audit query={{ version, assessmentId, scenarioId }} tableId={TableIds.auditScenario} />
            )}
          </PageContainer>
        </>
      )}
    </>
  );
};

export default ScenarioPage;
