import { Variable } from 'src/api/types';
import { Scope, SortState, VariableEdit } from './types';
import _ from 'lodash';
import * as Yup from 'yup';

export const genEmptyVariable = (scope: Scope): VariableEdit => {
  return {
    id: Date.now(),
    name: '',
    alias: null,
    // @ts-ignore
    value: undefined,
    currency: false,
    workspace_variable: scope === 'workspace',
    isInUse: [],
    isGlobal: false,
    _isNew: true,
  };
};

export const findChangedVariables = (original: VariableEdit[], modified: VariableEdit[]) => {
  return modified.filter((item) => {
    const originalItem = original.find((o) => o.id === item.id);
    if (!originalItem || item._isNew) {
      return false;
    }

    return (
      item.name !== originalItem.name || item.value !== originalItem.value || item.currency !== originalItem.currency
    );
  });
};

export const categorizeVariables = (input: VariableEdit[], original: VariableEdit[]) => {
  const newVariables = input.filter((el) => el._isNew).map((el) => _.omit(el, ['_isNew', 'id']));
  const changedVariables = findChangedVariables(original, input).map((el) =>
    el.isGlobal ? { ...el, value: el.value || null } : el,
  );
  const deletedVariables = original
    .filter((el) => !input.find((v) => v.id === el.id))
    .map((el) => {
      return { ...el, value: null };
    });

  console.log('categorizeVariables', {
    newVariables,
    changedVariables,
    deletedVariables,
  });

  return [...newVariables, ...changedVariables, ...deletedVariables];
};

const uniqueNameTest = {
  name: 'unique-name',
  // @ts-ignore
  test: function () {
    // @ts-ignore
    const { path, from } = this;

    const currentVariable = _.get(from[1].value, path.replace(/\.name$/, '')) as Variable;
    const workspaceVariable = currentVariable.workspace_variable;

    const values = from[1].value.variables
      .filter((v: any) => v.workspace_variable === workspaceVariable)
      .map((el: any) => el.name);

    const existingValue = _.get(from[1].value, path);
    const duplicate = _.filter(values, (v) => v === existingValue).length > 1;

    if (duplicate) {
      const errorMessage = workspaceVariable
        ? 'Workspace variable name must be unique.'
        : 'Assessment variable name must be unique.';

      // @ts-ignore
      return this.createError({
        path,
        message: errorMessage,
      });
    }

    return true;
  },
  message: 'Name must be unique',
};

const MAX_VARIABLES = 50;

export const validationSchema = Yup.object({
  variables: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        name: Yup.string().min(1, 'Name is too short').required('Required').test(uniqueNameTest),
        // value: Yup.number().positive('Value must be greater than 0').required('Required'),
        value: Yup.number()
          .nullable() // Allow null values
          .when('isGlobal', {
            is: true,
            then: Yup.number().nullable().positive('Value must be greater than 0').notRequired(), // Not required when isGlobal is true
            otherwise: Yup.number().nullable().positive('Value must be greater than 0').required('Required'), // Required when isGlobal is false
          }),
        currency: Yup.boolean().required('Required'),
        isInUse: Yup.array().of(Yup.string()).nullable(),
        workspace_variable: Yup.boolean().required('Required'),
      }),
    )
    .nullable()
    .max(MAX_VARIABLES, `You can add up to ${MAX_VARIABLES} variables`),
});

export const checkIsDirty = (arr1: VariableEdit[], arr2: VariableEdit[]) => {
  const sortedArr1 = _.sortBy(arr1, 'id');
  const sortedArr2 = _.sortBy(arr2, 'id');

  const areEqual = _.isEqual(sortedArr1, sortedArr2);

  return !areEqual;
};

export const sortVariables = (variables: VariableEdit[], sort: SortState) => {
  const [newVariables, existingVariables] = _.partition(variables, (el) => el._isNew);
  const sortedExisting = _.orderBy(
    existingVariables,
    [
      (el) => {
        const value = el[sort.by];
        return typeof value === 'string' ? value.toLowerCase() : value;
      },
    ],
    [sort.isAsc ? 'asc' : 'desc'],
  );
  const sorted = [...sortedExisting, ...newVariables];
  if (!_.isEqual(sorted, variables)) {
    return sorted;
  } else {
    return undefined;
  }
};

export const dummyData: Variable[] = [
  {
    id: 1,
    alias: 'alias',
    currency: false,
    isInUse: [],
    name: 'name',
    value: 5000,
    workspace_variable: false,
    isGlobal: true,
  },
  {
    id: 2,
    alias: 'alias2',
    currency: true,
    isInUse: [],
    name: 'name - cbc',
    value: 105000,
    workspace_variable: true,
    isGlobal: true,
  },
  {
    id: 3,
    alias: 'alias3',
    currency: true,
    isInUse: ['abc'],
    name: 'name - abc',
    value: 20,
    workspace_variable: true,
    isGlobal: true,
  },
];
