import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { FormValues, ScenarioNames } from '../types';
import { ReactComponent as WizzardIcon } from 'src/assets/icon-wizzard.svg';
import CurrencyInput from 'src/components/form/CurrencyInput';
import { get } from 'lodash';
import MaterialInput from 'src/components/form/MaterialInput';
import { Poppins, Tooltip } from 'src/common';
import colors from 'src/theme/colors';
import Input from 'src/components/form/Input';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: 20px auto 1fr 25% 22px;
  grid-gap: 10px;
  .row-item {
    /* border: 1px solid black; */
    display: flex;
    align-items: flex-start;

    &--center {
      display: flex;
      align-items: center !important;
      justify-content: center !important;
    }
  }
  .row-divider {
    border-bottom: 1px solid ${colors.aliceBlue};
  }

  .continous {
    /* border: 1px solid green; */
    display: grid;
    align-items: center;
    justify-content: stretch;
    height: 100%;
    flex: 1;
    grid-template-columns: 1fr auto;
    grid-gap: 24px;
    padding: 0 10px;
    &__input {
      display: flex;
      grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
      grid-gap: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .fixed-height {
    height: 50px;
  }

  .row-end {
    justify-content: flex-end;

    &--delete {
      svg {
        fill: ${colors.cflowerBlue};
      }
      &:hover {
        cursor: pointer;
        svg {
          fill: ${colors.red};
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .fixed-height {
      height: 38px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .fixed-height {
      height: 30px;
    }
  }
`;

interface RowProps {
  idx: number;
  onRemove: () => void;
  scenarioNames: ScenarioNames;
}

export const Row: React.FC<RowProps> = ({ idx, onRemove, scenarioNames }) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<FormValues>();
  const data = values.variables[idx];

  const isErrName = get(errors, `variables[${idx}].name`);
  const isTouchedName = get(touched, `variables[${idx}].name`);
  const isErrValue = get(errors, `variables[${idx}].value`);
  const isTouchedValue = get(touched, `variables[${idx}].value`);

  const canDelete = !data.isInUse.length && !data.isGlobal;

  return (
    <Div>
      <div className="row-item row-item--center fixed-height">
        {(data.workspace_variable || data.isGlobal) && <WizzardIcon />}
      </div>
      {data._isNew ? (
        <div className="row-item">
          <MaterialInput
            placeholder="Add variable name"
            value={data.name}
            onChange={(e) => setFieldValue(`variables[${idx}].name`, e.target.value)}
            onBlur={() => setFieldTouched(`variables[${idx}].name`, true)}
            error={Boolean(isTouchedName && isErrName)}
            helperText={isTouchedName && isErrName}
            data-cy="variable-name-input"
            fullWidth={true}
          />
        </div>
      ) : (
        <div className="row-item row-item--center">
          <div className="var">
            <div className="var__chip">
              <Poppins px={16} weight={500}>
                {data.name}
              </Poppins>
            </div>
          </div>
        </div>
      )}
      <div className="row-item fixed-height">
        <div className="continous">
          <div className="row-divider" />
          {!data.isGlobal && (
            <div className="continous__input">
              <Poppins px={14} weight={500} color="cflowerBlue" nowrap>
                CURRENCY
              </Poppins>
              <Input
                checked={data.currency}
                onChange={(e) => {
                  setFieldValue(`variables[${idx}].currency`, e.target.checked);
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Toggle currency',
                    modal: 'Variables modal',
                    tags: ['VARIABLES'],
                  });
                }}
                className="switch"
                type="checkbox"
                disabled={data.isGlobal}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row-item">
        <CurrencyInput
          placeholder="Enter value"
          value={data.value}
          currency={data.currency}
          onValueChange={(e) => setFieldValue(`variables[${idx}].value`, e.floatValue)}
          onBlur={() => setFieldTouched(`variables[${idx}].value`, true)}
          error={Boolean(isTouchedValue && isErrValue)}
          helperText={isTouchedValue && isErrValue}
          data-cy="variable-value-input"
          fullWidth={true}
        />
      </div>
      {canDelete ? (
        <div
          className="row-item row-item--center row-end row-end--delete fixed-height"
          onClick={() => {
            onRemove();
            mpEvent(MPEvents.ButtonClick, {
              button: 'Delete variable',
              modal: 'Variables modal',
            });
          }}
          data-cy="variable-delete"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
          </svg>
        </div>
      ) : (
        <div className="row-item row-item--center row-end fixed-height">
          {data.isInUse.length ? (
            <>
              <Tooltip
                dataHtml={`This variable can't be deleted because it is in use in the following scenarios:</br><strong>${scenarioNames[
                  data.id
                ].join(', ')}</strong>`}
                dataId={`row-${idx}`}
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
                    fill={colors.cflowerBlue}
                  />
                </svg>
              </Tooltip>
            </>
          ) : (
            <>{data.isGlobal && <></>}</>
          )}
        </div>
      )}
    </Div>
  );
};
