import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { Scenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { formatDate } from 'src/utils/misc';
import { filter } from 'lodash';
import numeral from 'numeral';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { IncompleteStateMarker } from 'src/common';
import { Link } from 'react-router-dom';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';

type UseColumns = (a: { data?: Scenario[]; genPath: (a: Scenario) => string | undefined }) => {
  columns: ColumnDef<Scenario>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = ['name', 'source', 'event', 'consequence', 'expected_loss', 'updated_at'];

export const useColumns: UseColumns = ({ data = [], genPath }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.compareAssessments}-columns`,
    defaultColumns,
  );

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm];

    const list: ColumnDef<Scenario>[] = [
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: (info) => {
          const path = genPath(info.row.original);
          if (path) {
            return (
              <Link
                className="table-link"
                to={path}
                onClick={() =>
                  mpEvent(MPEvents.Link, {
                    source: { value: ['Compare Assessments'] },
                    destination: { value: [path.startsWith('/compare') ? 'Compare Scenarios' : 'Scenario'] },
                  })
                }
              >
                {info.getValue()}
              </Link>
            );
          }
          return <span>{info.getValue()}</span>;
        },
        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'source',
        header: 'Source',
        accessorFn: (row) => riskSourcesNames[row.source] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'event',
        header: 'Event',
        accessorFn: (row) => riskEventsNames[row.event] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'consequence',
        header: 'Consequence',
        accessorFn: (row) => riskConsequencesNames[row.consequence] || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'risk_owner',
        header: 'Risk Owner',
        accessorFn: (row) => row.risk_owner?.text || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'expected_loss',
        header: 'Expected Loss',
        accessorFn: (row) => row.expected_loss || null,
        cell: (info) => numeral(info.getValue()).format('$0a'),
      },
      {
        id: '_control_count',
        header: 'Linked Controls',
        accessorFn: (row) => row.implementationPlans?.length || 0,
        cell: (info) => info.getValue(),
      },
      {
        id: 'updated_at',
        header: 'Last Updated',
        accessorFn: (row) => row.updated_at || null,
        cell: ({ row }) => formatDate(row.original.updated_at),
      },
      {
        id: 'created_at',
        header: 'Created Date',
        accessorFn: (row) => row.created_at || null,
        cell: ({ row }) => formatDate(row.original.created_at),
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm extraComponentLeft={<IncompleteStateMarker scenario={row.original} />} />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'name', title: 'Name' },
        { key: 'source', title: 'Source' },
        { key: 'event', title: 'Event' },
        { key: 'consequence', title: 'Consequence' },
        {
          key: 'risk_owner',
          title: 'Risk Owner',
        },
        {
          key: 'expected_loss',
          title: 'Expected Loss',
        },
        {
          key: '_control_count',
          title: 'Linked Controls',
        },
        {
          key: 'updated_at',
          title: 'Last Updated',
        },
        {
          key: 'created_at',
          title: 'Created Date',
        },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported };
};
