import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getAssessments } from 'src/api/assessment';
import { QKeys, Variable } from 'src/api/types';
import { filter } from 'lodash';
import { ScenarioNames, VariableEdit } from './types';

type UseScenarioNames = (a: { variables: VariableEdit[] }) => {
  scenarioNames: ScenarioNames;
};

const useScenarioNames: UseScenarioNames = ({ variables }) => {
  const [scenarioNames, setScenarioNames] = useState<ScenarioNames>({});
  const { data: assessments = [] } = useQuery([QKeys.Assessments], getAssessments);

  useEffect(() => {
    const names: ScenarioNames = {};
    variables.forEach((el: Variable) => {
      const n = filter(
        el.isInUse.map((id) => {
          return assessments
            ?.map((el) => el.scenarios)
            .flat()
            .find((s) => s.id === id)?.name as string;
        }),
        (value) => value !== undefined,
      );

      names[el.id] = n;
    });

    setScenarioNames(names);
  }, [assessments, variables]);

  return { scenarioNames };
};

export default useScenarioNames;
