import React from 'react';
import { CLegend } from 'src/components/charts/comps';
import colors from 'src/theme/colors';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

interface LegendComposedProps {}

export const LegendComposed: React.FC<LegendComposedProps> = () => {
  return (
    <CLegend
      legends={[
        {
          id: 'leg-base-m',
          title: 'Unmodified Risk',
          type: 'line',
          color: colors.prussianBlue,
          tooltip: 'Represents risk without modifications applied',
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Unmodified Risk',
              tags: ['SCENARIO'],
            }),
        },
        {
          id: 'leg-s1-m',
          title: 'Live Risk',
          type: 'line',
          color: colors.gradientPart,
          tooltip: 'Represents risk with modifications from live controls applied',
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Live Risk',
              tags: ['SCENARIO'],
            }),
        },
        {
          id: 'leg-s2-m',
          title: 'Projected Risk',
          type: 'line',
          color: colors.brightBlue,
          tooltip: 'Represents risk with modifications from all controls applied',
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Projected Risk',
              tags: ['SCENARIO'],
            }),
        },
      ]}
    />
  );
};
