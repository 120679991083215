import { AxiosArgs, ThenArgs, Scenario, Modifiers } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { QueryKey } from 'react-query';
import { encodeBody, encodeQueryParams } from 'src/api/client';

type GetScenario = (
  key: QueryKey,
  parameters: {
    scenarioId: string | number;
    version?: string;
    variant?: 'base' | 'live' | 'complete';
  },
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Scenario>>>>;

export const getScenario: GetScenario = (_, { scenarioId, version, variant }) => {
  const path = `/api/public/api/scenarios/${scenarioId}${encodeQueryParams({ version, variant })}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type CreateScenario = (parameters: Partial<Scenario>) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Scenario>>>>;

export const createScenario: CreateScenario = (parameters) => {
  const path = '/api/public/api/scenarios';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateScenario = (
  parameters: Partial<Omit<Scenario, 'sim_losses' | 'expected_loss'>> & {
    id: number | string;
  },
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Scenario>>>>;

export const updateScenario: UpdateScenario = ({ id, ...parameters }) => {
  const path = `/api/public/api/scenarios/${id}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type DeleteScenario = (parameters: { id: string | number }) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Scenario>>>>;

export const deleteScenario: DeleteScenario = ({ id }) => {
  const path = `/api/public/api/scenarios/${id}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetRiskOwners = (key: QueryKey, parameters: {}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<string[]>>>>;

export const getRiskOwners: GetRiskOwners = () => {
  const path = `/api/public/api/scenarios/riskOwners`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type ManageRisk = (parameters: any) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Scenario>>>>;

export const manageRisk: ManageRisk = (parameters) => {
  const path = `/api/public/api/scenarios/manage-risk/${parameters.ux_id}`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

interface ChartData {
  chart: { x: number; y: number }[];
}

interface ChartResponse {
  data: {
    completeChart: ChartData;
    liveChart: ChartData;
  };
}

type CalcModChart = (parameters: { scenario_id: string; modifiers: Modifiers[] }) => Promise<ChartResponse>;

export const calcModChart: CalcModChart = (parameters) => {
  const path = `/api/public/api/scenarios/modified-chart`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data);
};
