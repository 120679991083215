import React from 'react';
import { CLegend } from 'src/components/charts/comps';
import useVersion from 'src/hooks/useVersion';
import colors from 'src/theme/colors';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

interface LegendComposedProps {
  isLegacyVersion: boolean;
}

export const LegendComposed: React.FC<LegendComposedProps> = ({ isLegacyVersion }) => {
  const { version } = useVersion();
  return (
    <CLegend
      legends={
        version && !isLegacyVersion
          ? [
              {
                id: 'leg-base-m',
                title: 'Unmodified Risk',
                type: 'line',
                color: colors.prussianBlue,
                tooltip: 'Represents risk without modifications applied',
                onClick: () =>
                  mpEvent(MPEvents.GraphLegendToggle, {
                    value: 'Unmodified Risk',
                    tags: ['ASSESSMENT'],
                  }),
              },
              {
                id: 'leg-s1-m',
                title: 'Live Risk',
                type: 'line',
                color: colors.gradientPart,
                tooltip: 'Represents risk with modifications from live controls applied',
                onClick: () =>
                  mpEvent(MPEvents.GraphLegendToggle, {
                    value: 'Live Risk',
                    tags: ['ASSESSMENT'],
                  }),
              },
              {
                id: 'leg-s2-m',
                title: 'Projected Risk',
                type: 'line',
                color: colors.brightBlue,
                tooltip: 'Represents risk with modifications from all controls applied',
                onClick: () =>
                  mpEvent(MPEvents.GraphLegendToggle, {
                    value: 'Projected Risk',
                    tags: ['ASSESSMENT'],
                  }),
              },
              {
                title: 'Risk Tolerance',
                id: 'leg-rt',
                type: 'dash',
                color: colors.cflowerBlue,
                onClick: () =>
                  mpEvent(MPEvents.GraphLegendToggle, {
                    value: 'Risk Tolerance',
                    tags: ['ASSESSMENT'],
                  }),
              },
            ]
          : [
              {
                title: 'Loss Exceedance',
                id: 'legend1',
                type: 'line',
                color: colors.gradientPart,
                onClick: () =>
                  mpEvent(MPEvents.GraphLegendToggle, {
                    value: 'Loss Exceedance',
                    tags: ['ASSESSMENT'],
                  }),
              },
              {
                title: 'Risk Tolerance',
                id: 'leg-rt',
                type: 'dash',
                color: colors.cflowerBlue,
                onClick: () =>
                  mpEvent(MPEvents.GraphLegendToggle, {
                    value: 'Risk Tolerance',
                    tags: ['ASSESSMENT'],
                  }),
              },
            ]
      }
    />
  );
};
