import React, { Fragment } from 'react';
import styled from 'styled-components';
import colors from 'src/theme/colors';
import { Poppins } from 'src/common';

const Tr = styled.tr<{ $itemHeight: number }>`
  font-size: 14px;
  font-weight: 500;
  height: ${({ $itemHeight }) => $itemHeight}px !important;
  border-top: 1px solid ${colors.stroke};
`;

interface GTRowEmptyProps {
  itemHeight?: number;
  text?: string;
}

export const GTRowEmpty = ({ itemHeight = 77, text = 'No activity recorded yet' }: GTRowEmptyProps) => {
  return (
    <Fragment>
      <Tr $itemHeight={itemHeight}>
        <td />
        <td colSpan={999}>
          <Poppins px={14} weight={500} color="cflowerBlue">
            {text}
          </Poppins>
        </td>
      </Tr>
    </Fragment>
  );
};
