import React from 'react';
import Chart from 'src/components/charts/Chart';
import { useQuery } from 'react-query';
import { ProjectionVariant, QKeys } from 'src/api/types';
import { getScenario } from 'src/api/scenario';
import useVersion from 'src/hooks/useVersion';
import { useParams } from 'react-router-dom';
import colors from 'src/theme/colors';
import { CSkeleton } from 'src/common';
import _ from 'lodash';

interface VersionedChartProps {
  isLogarithmic: boolean;
  isLegacyVersion: boolean;
}

export const VersionedChart: React.FC<VersionedChartProps> = ({ isLogarithmic, isLegacyVersion }) => {
  const { scenarioId } = useParams<{ scenarioId: string }>();
  const { version } = useVersion();

  const { data: scenarioLive, isLoading: isLiveLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, variant: ProjectionVariant.live, version }],
    getScenario,
  );
  const { data: scenarioBase, isLoading: isBaseLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, variant: ProjectionVariant.base, version }],
    getScenario,
  );
  const { data: dataComplete, isLoading: isCompleteLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, variant: ProjectionVariant.complete, version }],
    getScenario,
  );

  const isLoading = isLiveLoading || isCompleteLoading || isBaseLoading;

  if (isLoading) return <CSkeleton height={250} />;

  return (
    <Chart
      isLogarithmic={isLogarithmic}
      input={
        !isLegacyVersion
          ? [
              {
                data: scenarioBase?.chart || [],
                upper: scenarioBase?.upper || 1,
                lower: scenarioBase?.lower || 1,
                name: 'Unmodified Risk',
                color: colors.prussianBlue,
                fillColor: 'rgba(2, 3, 51, 0.07)',
                legendId: 'leg-base-m',
              },
              {
                data: scenarioLive?.chart || [],
                upper: scenarioLive?.upper || 1,
                lower: scenarioLive?.lower || 1,
                name: 'Live Risk',
                color: colors.gradientPart,
                fillColor: 'rgba(230, 46, 97, 0.07)',
                legendId: 'leg-s1-m',
              },
              {
                data: dataComplete?.chart || [],
                upper: dataComplete?.upper || 1,
                lower: dataComplete?.lower || 1,
                name: 'Projected Risk',
                color: colors.brightBlue,
                fillColor: 'rgba(100, 149, 237, 0.07)',
                legendId: 'leg-s2-m',
              },
            ]
          : [
              {
                data: scenarioBase?.chart || [],
                upper: scenarioBase?.upper || 1,
                lower: scenarioBase?.lower || 1,
                name: 'Loss Exceedance',
                color: colors.gradientPart,
                fillColor: colors.aliceBlue,
                legendId: 'legend1',
              },
            ]
      }
    />
  );
};
