import { AuditItem, AxiosArgs, PdfAssessment, ThenArgs } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody, encodeQueryParams } from 'src/api/client';
import { progressLoad, progressSave } from 'src/components/Wizzard/util';
import { WizardFormValues } from './types/wizard';

type RequestAssistance = (parameters: {
  text: string;
  assessmentId: string;
  scenarioId?: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse>>>;

export const requestAssistance: RequestAssistance = (parameters) => {
  const path = `/api/public/api/request-assistance`;

  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data);
};
// ------------------------------------------------------------

type GetAudit = (params: {
  assessmentId?: string;
  scenarioId?: string;
  version?: string;
  skip?: number;
  dir?: 'desc' | 'asc';
  orderBy?: string;
  user?: string | number;
  organisation?: string;
  workspace?: string;
  endPath?: 'controls';
  controlId?: string;
}) => Promise<
  AxiosArgs<
    ThenArgs<
      AxiosResponse<{
        total: number;
        items: AuditItem[];
      }>
    >
  >
>;

export const getAudit: GetAudit = ({ endPath = '', ...params }) => {
  const path = `/api/public/api/audit${endPath ? `/${endPath}` : ''}${encodeQueryParams(params)}`;

  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data);
};
// ------------------------------------------------------------
// ------------------------------------------------------------
type InvokeAuth0CleanUp = () => Promise<void>;

export const callCleanUp: InvokeAuth0CleanUp = () => {
  const path = `/api/public/api/access-check-clean-up`;
  return axios({
    method: 'POST',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};
// ------------------------------------------------------------

type GetPdfAssessment = (parameters: {
  assessmentId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<PdfAssessment>>>>;

export const getPdfAssessment: GetPdfAssessment = ({ assessmentId }) => {
  const path = `/api/public/api/compiled/${assessmentId}`;

  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetPdfScenario = (parameters: {
  assessmentId: string;
  scenarioId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<PdfAssessment>>>>;

export const getPdfScenario: GetPdfScenario = ({ assessmentId, scenarioId }) => {
  const path = `/api/public/api/compiled/${assessmentId}/${scenarioId}`;

  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetWizard = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<WizardFormValues | null>>>>;

export const getWizard: GetWizard = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(progressLoad<WizardFormValues>('__cydea_wizzard-organisation__'));
    }, 2000);
  });
};
// ------------------------------------------------------------

type UpdateWizard = (data: WizardFormValues) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const updateWizard: UpdateWizard = (data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(progressSave(data, '__cydea_wizzard-organisation__'));
    }, 2000);
  });
};
// ------------------------------------------------------------
