import React from 'react';
import styled from 'styled-components';
import { Item } from './comps/Item';
import { LegendParams } from './types';

const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
  grid-gap: 26px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
  }
`;

interface CLegendProps {
  legends: LegendParams[];
}

export const CLegend: React.FC<CLegendProps> = ({ legends }) => {
  return (
    <Div>
      {legends.map((el) => (
        <Item key={el.id} {...el} />
      ))}
    </Div>
  );
};
