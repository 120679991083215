import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getAssessment } from 'src/api/assessment';
import { ProjectionVariant, QKeys } from 'src/api/types';
import { CSkeleton } from 'src/common';
import Chart from 'src/components/charts/Chart/Chart';
import { getMinMax, parseRiskTolerance } from 'src/components/charts/util';
import useVersion from 'src/hooks/useVersion';
import colors from 'src/theme/colors';

interface VersionedChartProps {
  isLogarithmic: boolean;
  isLegacyVersion: boolean;
}

export const VersionedChart: React.FC<VersionedChartProps> = ({ isLogarithmic, isLegacyVersion }) => {
  const { assessmentId } = useParams();
  const { version } = useVersion();

  const { data: baseData, isLoading: isLoadingBase } = useQuery(
    [QKeys.Assessment, { assessmentId, variant: ProjectionVariant.base, version }],
    getAssessment,
  );
  const { data: liveData, isLoading: isLoadingLive } = useQuery(
    [QKeys.Assessment, { assessmentId, variant: ProjectionVariant.live, version }],
    getAssessment,
  );
  const { data: completeData, isLoading: isLoadingComplete } = useQuery(
    [QKeys.Assessment, { assessmentId, variant: ProjectionVariant.complete, version }],
    getAssessment,
  );

  const isLoading = isLoadingBase || isLoadingLive || isLoadingComplete;

  if (isLoading) return <CSkeleton height={250} />;

  return (
    <Chart
      isLogarithmic={isLogarithmic}
      input={
        !isLegacyVersion
          ? [
              {
                data: baseData?.chart || [],
                upper: getMinMax(baseData?.scenarios).upper || 1,
                lower: getMinMax(baseData?.scenarios).lower || 1,
                name: 'Unmodified Risk',
                color: colors.prussianBlue,
                fillColor: 'rgba(2, 3, 51, 0.07)',
                legendId: 'leg-base-m',
              },
              {
                data: liveData?.chart || [],
                upper: getMinMax(liveData?.scenarios).upper || 1,
                lower: getMinMax(liveData?.scenarios).lower || 1,
                name: 'Live Risk',
                color: colors.gradientPart,
                fillColor: 'rgba(230, 46, 97, 0.07)',
                legendId: 'leg-s1-m',
              },
              {
                data: completeData?.chart || [],
                upper: getMinMax(completeData?.scenarios).upper || 1,
                lower: getMinMax(completeData?.scenarios).lower || 1,
                name: 'Projected Risk',
                color: colors.brightBlue,
                fillColor: 'rgba(100, 149, 237, 0.07)',
                legendId: 'leg-s2-m',
              },
              {
                data: parseRiskTolerance(baseData?.risk_tolerance || ''),
                name: 'Risk Tolerance',
                type: 'spline',
                dashStyle: 'ShortDash',
                legendId: 'leg-rt',
                fillColor: colors.cflowerBlue,
                dataLabels: {
                  enabled: false,
                },
                color: colors.cflowerBlue,
                isRiskTolerance: true,
              },
            ]
          : [
              {
                data: baseData?.chart || [],
                upper: getMinMax(baseData?.scenarios || []).upper || 1,
                lower: getMinMax(baseData?.scenarios || []).lower || 1,
                name: 'Loss Exceedance',
                color: colors.gradientPart,
                fillColor: colors.aliceBlue,
                legendId: 'legend1',
              },
              {
                data: parseRiskTolerance(baseData?.risk_tolerance),
                name: 'Risk Tolerance',
                type: 'spline',
                dashStyle: 'ShortDash',
                legendId: 'leg-rt',
                fillColor: colors.cflowerBlue,
                dataLabels: {
                  enabled: false,
                },
                color: colors.cflowerBlue,
                isRiskTolerance: true,
              },
            ]
      }
    />
  );
};
