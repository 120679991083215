// @ts-nocheck
import React from 'react';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import { Scenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import colors from 'src/theme/colors';
import numeral from 'numeral';
import { isUndefined } from 'src/utils/misc';
import { pdfStyles } from '../pdfStyles';
import { NoData } from './NoData';
import { getExternalPath } from '../util';
import { extLinkImg } from '../imgData';

interface ScenariosTableProps {
  data: Scenario[];
}
export const ScenariosTable: React.FC<ScenariosTableProps> = ({ data }) => {
  console.log('data', data);
  return (
    <View>
      <Text style={pdfStyles.label}>RISK SCENARIOS</Text>
      <View style={pdfStyles.spacer4} />
      {data.length ? (
        <>
          <View
            wrap={false}
            break={false}
            style={{
              height: 24,
              border: `1px solid ${colors.cflowerBlue}`,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View style={{ ...pdfStyles.tableCell, flex: 1.5 }}>
              <Text style={pdfStyles.tableHeader}>Name</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
              <Text style={pdfStyles.tableHeader}>Risk Owner</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
              <Text style={pdfStyles.tableHeader}>Sources</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
              <Text style={pdfStyles.tableHeader}>Events</Text>
            </View>
            <View style={pdfStyles.tableCell}>
              <Text style={pdfStyles.tableHeader}>Consequences</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.9 }}>
              <Text style={pdfStyles.tableHeader}>Expected Loss</Text>
            </View>
          </View>
          {data.map((el, idx) => (
            <View
              wrap={false}
              break={false}
              style={{
                minHeight: 24,
                border: `1px solid ${colors.cflowerBlue}`,
                display: 'flex',
                flexDirection: 'row',
                marginTop: -1,
              }}
            >
              <View style={{ ...pdfStyles.tableCell, flex: 1.5 }}>
                <Link
                  src={getExternalPath(`risk-assessments/${el.assessment_id}/scenario/${el.id}`)}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    textDecoration: 'none',
                  }}
                >
                  <Text style={{ ...pdfStyles.tableValue, textDecoration: 'underline', marginRight: 6 }}>
                    {el.name || '-'}
                  </Text>
                  <Image src={extLinkImg} style={{ width: 8, height: 8 }} />
                </Link>
              </View>
              <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
                <Text style={pdfStyles.tableValue}>{el.risk_owner?.text || '-'}</Text>
              </View>
              <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
                <Text style={pdfStyles.tableValue}>{!isUndefined(el.source) ? riskSourcesNames[el.source] : '-'}</Text>
              </View>
              <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
                <Text style={pdfStyles.tableValue}>{!isUndefined(el.event) ? riskEventsNames[el.event] : '-'}</Text>
              </View>
              <View style={pdfStyles.tableCell}>
                <Text style={pdfStyles.tableValue}>
                  {!isUndefined(el.consequence) ? riskConsequencesNames[el.consequence] : '-'}
                </Text>
              </View>
              <View style={{ ...pdfStyles.tableCell, flex: 0.9 }}>
                <Text style={{ ...pdfStyles.tableValue, fontWeight: 600, fontSize: 10 }}>
                  {el.expected_loss ? numeral(el.expected_loss).format('$0a') : '-'}
                </Text>
              </View>
            </View>
          ))}
        </>
      ) : (
        <NoData />
      )}
    </View>
  );
};
