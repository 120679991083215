import React, { useEffect, useRef, useState } from 'react';
import { Document, PDFViewer, Font } from '@react-pdf/renderer';
import fontRegular from 'src/assets/fonts/Poppins-Regular.ttf';
import fontMedium from 'src/assets/fonts/Poppins-Medium.ttf';
import fontBold from 'src/assets/fonts/Poppins-Bold.ttf';
import { Control, Incident, PdfAssessment as PdfAssessmentType } from 'src/api/types';
import _ from 'lodash';
import { HtmlWrap, SnapChartImageV2 } from '../comps';
import { Toc, TocItem } from '../types';
import {
  AssessmentSummary,
  AssessmentEnd,
  ScenRender,
  AssessmentHead,
  AssessmentToc,
  AssessmentExecSummary,
  RmpRender,
  IncidentsRender,
} from './comps';
import { useAuth } from 'src/state/auth';
import { getMinMax, parseRiskTolerance } from 'src/components/charts/util';
import { getPdfAssessment } from 'src/api/other';
import { useQuery } from 'react-query';
import { IS_DEBUG } from '../conf';
import colors from 'src/theme/colors';
import { ChartConf } from 'src/components/charts/Chart/Chart';

Font.registerHyphenationCallback((word) => [word]);

Font.register({
  family: 'Poppins',

  fonts: [
    {
      src: fontRegular,
      fontWeight: 400,
    },
    {
      src: fontMedium,
      fontWeight: 500,
    },
    {
      src: fontBold,
      fontWeight: 600,
    },
  ],
});

interface PdfAssessmentProps {
  assessmentId: string;
  isLogarithmic: boolean;
  renderScenarios?: string[];
  onClose: () => void;
  includesExecSummary: boolean;
  includesRMP: boolean;
  includesIncidents: boolean;
  includesScenarios: boolean;
  includesRiskTolerance: boolean;
}

const PdfAssessment: React.FC<PdfAssessmentProps> = ({
  assessmentId,
  isLogarithmic,
  renderScenarios = [],
  onClose,
  includesExecSummary,
  includesRMP,
  includesIncidents,
  includesScenarios,
  includesRiskTolerance,
}) => {
  const [tableOfContents, setTableOfContents] = useState<{
    hasExecResult: boolean;
    hasAssessmentResult: boolean;
    hasScenarioResult: boolean;
    hasRmpResult: boolean;
    hasIncidentsResult: boolean;
    contents: Toc[];
  }>({
    hasExecResult: !includesExecSummary,
    hasAssessmentResult: false,
    hasScenarioResult: !renderScenarios.length,
    hasRmpResult: !includesRMP,
    hasIncidentsResult: !includesIncidents,
    contents: [],
  });

  const [charts, setCharts] = useState<{
    hasChartsResults: boolean;
    assessmentChart: string;
    scenarioCharts: string[];
  }>({ hasChartsResults: false, assessmentChart: '', scenarioCharts: [] });
  const [data, setData] = useState<{ original: PdfAssessmentType; mapped: PdfAssessmentType } | null>(null);
  const [controls, setControls] = useState<Control[]>([]);
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const { user } = useAuth();

  // const [renderCount, setRenderCount] = useState(0);
  const renderCount = useRef(0);

  useEffect(() => {
    const contents: Toc[] = [];
    if (includesExecSummary) {
      contents.push({ title: 'Executive Summary', pageNumber: -1, id: 'exec.summary' });
    }
    contents.push({ title: 'Assessment Summary', pageNumber: -1, id: data?.original.name.toLowerCase() });
    if (includesScenarios && renderScenarios.length) {
      contents.push({ title: 'Scenarios', pageNumber: -1 });
    }
    if (includesRMP) {
      contents.push({ title: 'Risk Management Plan', pageNumber: -1, id: 'rmp.plan' });
    }
    if (includesIncidents) {
      contents.push({ title: 'Incidents', pageNumber: -1, id: 'incidents' });
    }
    setTableOfContents((s) => {
      return { ...s, contents };
    });
  }, [includesExecSummary, renderScenarios, includesRMP]);

  useQuery(['getPdfAssessment', { assessmentId }], () => getPdfAssessment({ assessmentId }), {
    onSuccess: async (res) => {
      await new Promise((resolve) => setTimeout(resolve, 10));
      const scenarios = res.scenarios.filter((el) => renderScenarios.includes(el.id));
      const data = { ...res, scenarios };
      setData({ original: res, mapped: data });

      if (includesRMP) {
        const uniqControls = _.uniqBy(scenarios.map((el) => el.controls).flat(), 'ux_id');
        setControls(uniqControls);
      }

      if (includesIncidents) {
        const incidents = scenarios.map((el) => el.incidents).flat();
        const uniqIncidents = _.uniqBy(incidents, 'id');
        setIncidents(uniqIncidents);
      }

      const contents = _.cloneDeep(tableOfContents?.contents) as Toc[];
      const idx = contents.findIndex((el) => el.title === 'Assessment Summary');
      contents[idx].id = data.name.toLowerCase();

      setTableOfContents((s) => {
        return { ...s, contents };
      });
    },
    onError: onClose,
    cacheTime: 0,
  });

  const onExecDone = async (pageNumber: number) => {
    if (!tableOfContents.hasExecResult) {
      const contents = _.cloneDeep(tableOfContents?.contents) as Toc[];
      const idx = contents.findIndex((el) => el.title === 'Executive Summary');
      contents[idx].pageNumber = pageNumber;

      setTableOfContents((s) => {
        return { ...s, hasExecResult: true, contents };
      });
    }
  };

  const onAssessmentDone = (pageNumber: number) => {
    if (tableOfContents.hasExecResult && !tableOfContents.hasAssessmentResult) {
      const contents = _.cloneDeep(tableOfContents.contents) as Toc[];
      const idx = contents.findIndex((el) => el.title === 'Assessment Summary');
      contents[idx].pageNumber = pageNumber;

      setTableOfContents((s) => {
        return { ...s, hasAssessmentResult: true, contents };
      });
    }
  };

  const onScenariosDone = async (data: TocItem[]) => {
    if (tableOfContents.hasExecResult && tableOfContents.hasAssessmentResult && !tableOfContents.hasScenarioResult) {
      const contents = _.cloneDeep(tableOfContents?.contents) as Toc[];
      const idx = contents.findIndex((el) => el.title === 'Scenarios');
      contents[idx].children = data;
      contents[idx].pageNumber = data[0].pageNumber;
      contents[idx].id = data[0].title.toLowerCase();

      setTableOfContents((s) => {
        return { ...s, hasScenarioResult: true, contents };
      });
    }
  };

  const onRmpDone = (pageNumber: number, data: TocItem[]) => {
    if (
      tableOfContents.hasExecResult &&
      tableOfContents.hasAssessmentResult &&
      tableOfContents.hasScenarioResult &&
      !tableOfContents.hasRmpResult
    ) {
      const contents = _.cloneDeep(tableOfContents.contents) as Toc[];
      const idx = contents.findIndex((el) => el.title === 'Risk Management Plan');
      contents[idx].pageNumber = pageNumber;
      contents[idx].children = data;

      setTableOfContents((s) => {
        return { ...s, hasRmpResult: true, contents };
      });
    }
  };

  const onIncidentsDone = (pageNumber: number, data: TocItem[]) => {
    if (
      tableOfContents.hasExecResult &&
      tableOfContents.hasAssessmentResult &&
      tableOfContents.hasScenarioResult &&
      tableOfContents.hasRmpResult &&
      !tableOfContents.hasIncidentsResult
    ) {
      const contents = _.cloneDeep(tableOfContents.contents) as Toc[];
      const idx = contents.findIndex((el) => el.title === 'Incidents');
      contents[idx].pageNumber = pageNumber;
      contents[idx].children = data;

      setTableOfContents((s) => {
        return { ...s, hasIncidentsResult: true, contents };
      });
    }
  };

  // FILE DOWNLOAD
  const print = (blob: Blob | undefined) => {
    if (blob) {
      let elm = document.createElement('a');
      elm.href = URL.createObjectURL(blob);
      elm.setAttribute('download', `${data?.original.name}.pdf`);
      elm.click();
      elm.remove();
      onClose();
    }
  };

  if (!data) return null;

  const assessmentCharts: ChartConf[] = [
    {
      data: data.original.chart || [],
      upper: getMinMax(data.original.scenarios).upper,
      lower: getMinMax(data.original.scenarios).lower,
      color: colors.gradientPart,
      fillColor: 'rgba(230, 46, 97, 0.07)',
      name: 'chart',
    },
  ];

  if (includesRiskTolerance) {
    assessmentCharts.push({
      data: parseRiskTolerance(data.original?.risk_tolerance || ''),
      name: 'rt',
      type: 'spline',
      dashStyle: 'ShortDash',
      fillColor: colors.cflowerBlue,
      color: colors.cflowerBlue,
      isRiskTolerance: true,
    });
  }

  return (
    <HtmlWrap $isTest={IS_DEBUG}>
      <SnapChartImageV2
        disabled={charts.hasChartsResults}
        isLogarithmic={isLogarithmic}
        chartConf={[
          {
            charts: assessmentCharts,
          },
        ]}
        onSnap={(img) =>
          setCharts((s) => {
            return { ...s, assessmentChart: img[0], hasChartsResults: !data.mapped.scenarios.length };
          })
        }
      />

      <SnapChartImageV2
        disabled={charts.hasChartsResults || !data.mapped.scenarios.length}
        isLogarithmic={isLogarithmic}
        chartConf={data.mapped.scenarios.map((el) => {
          return {
            charts: [
              {
                data: el.chart || [],
                upper: el.upper,
                lower: el.lower,
                color: colors.gradientPart,
                fillColor: 'rgba(230, 46, 97, 0.07)',
                name: 'chart',
              },
            ],
          };
        })}
        onSnap={(img) =>
          setCharts((s) => {
            return {
              ...s,
              scenarioCharts: img,
              hasChartsResults: true,
            };
          })
        }
      />
      {charts.hasChartsResults && (
        // @ts-ignore
        <PDFViewer>
          {/* @ts-ignore */}
          <Document
            onRender={(el) => {
              let requiredRenders = 1;
              if (includesExecSummary) {
                requiredRenders += 1;
              }
              if (data.mapped.scenarios.length) {
                requiredRenders += 1;
              }
              if (includesRMP) {
                requiredRenders += 1;
              }
              if (includesIncidents) {
                requiredRenders += 1;
              }
              if (renderCount.current === requiredRenders) {
                if (!IS_DEBUG) {
                  print(el.blob);
                }
              } else {
                // setRenderCount((s) => s + 1);
                renderCount.current += 1;
              }
            }}
          >
            <AssessmentHead user={user} assessment={data.original} />
            <AssessmentToc contents={tableOfContents.contents} />
            {includesExecSummary && (
              <AssessmentExecSummary onDone={onExecDone} execSummary={data.original.executive_summary} />
            )}

            <AssessmentSummary
              onDone={onAssessmentDone}
              assessmentOriginal={data.original}
              assessmentMapped={data.original}
              chart={charts.assessmentChart}
              includesRMP={includesRMP}
              includesRiskTolerance={includesRiskTolerance}
            />

            {renderScenarios.length && tableOfContents.hasAssessmentResult && (
              <ScenRender
                scenarios={data.mapped.scenarios.map((el, idx) => {
                  return { ...el, chartImage: charts.scenarioCharts[idx] };
                })}
                onDone={onScenariosDone}
                assessmentData={{
                  reviewDate: data.original.review_date,
                  name: data.original.name,
                  id: data.original.id,
                }}
              />
            )}
            {includesRMP && <RmpRender onDone={onRmpDone} assessmentOriginal={data.original} controls={controls} />}
            {includesIncidents && (
              <IncidentsRender onDone={onIncidentsDone} assessmentOriginal={data.original} incidents={incidents} />
            )}

            <AssessmentEnd user={user} assessment={data.original} />
          </Document>
        </PDFViewer>
      )}
    </HtmlWrap>
  );
};

export default PdfAssessment;
